import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notification(props) {
  const { x, y, notification, setnotification, error } = props;
  const { open, loading, message } = notification;

  const handleClose = () => {
    setnotification({ ...notification, open: false });
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: x ? x : "top", horizontal: y ? y : "center" }}
        open={open}
        onClose={handleClose}
        message={message}
        key={(x ? x : "top") + (y ? y : "center")}
      >
        <Alert
          icon={loading ? <CircularProgress sx={{ fontSize: "0.5rem", color: "#fff" }} size={22} /> : ""}
          onClose={loading ? null : handleClose}
          severity={error ? "error" : loading ? "info" : "success"}
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
