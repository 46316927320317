export const baseURL = "https://api.sketshare.com";

export async function signInAccount(email: string, password: string) {
  const response = await fetch(`${baseURL}/api/auth/login`, {
    method: "POST",
    body: JSON.stringify({ email: email, password: password }),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();
  return data;
}

export async function createUser(
  name: string,
  email: string,
  password: string
) {
  const body = {
    name: name,
    email: email,
    password: password,
  };
  const response = await fetch(`${baseURL}/api/auth/signup`, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function recoverPassword(email: string) {
  const response = await fetch(`${baseURL}/api/auth/forget-password`, {
    method: "POST",
    body: JSON.stringify({ email: email }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function resendVerificationEmail(email: string) {
  const response = await fetch(`${baseURL}/api/auth/resend-verification-email`, {
    method: "POST",
    body: JSON.stringify({ email: email }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function verifyEmailClientSide(token: string) {
  const response = await fetch(`${baseURL}/api/auth/email-verification`, {
    method: "POST",
    body: JSON.stringify({ token: token }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function newPasswordClientSide(token: string, password: string) {
  const response = await fetch(`${baseURL}/api/auth/reset-password?token=${token}`, {
    method: "POST",
    body: JSON.stringify({ password: password }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function createCanvas(token: any, canvas?: object) {
  const response = await fetch(`${baseURL}/api/canvas/create`, {
    method: "POST",
    body: JSON.stringify(canvas),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function updateCanvas(token: any, canvas: object, id: string) {
  const response = await fetch(`${baseURL}/api/canvas/update/${id}`, {
    method: "PUT",
    body: JSON.stringify(canvas),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function renameCanvas(token: any, canvas: object, id: string) {
  const response = await fetch(`${baseURL}/api/canvas/rename/${id}`, {
    method: "PUT",
    body: JSON.stringify(canvas),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function deleteCanvas(token: any, id: string) {
  const response = await fetch(`${baseURL}/api/canvas/delete/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function getAllCanvas(token: string) {
  const response = await fetch(`${baseURL}/api/canvas/get-all-canvas`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return data;
}

export async function getSharedWithMeCanvas(token: string) {
  const response = await fetch(`${baseURL}/api/canvas/get-shared-me-canvas`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const data = await response.json();
  return data;
}

export async function getCanvas(token: any, id: string) {
  const response = await fetch(`${baseURL}/api/canvas/get-canvas/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function create100msRoom(token: any, canvasId?: string) {
  const response = await fetch(`${baseURL}/api/canvas/create-100ms-room/${canvasId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function fetchProfile(token: any) {
  const response = await fetch(`${baseURL}/api/auth/profile`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function updateProfile(token: any, profile: object) {
  const response = await fetch(`${baseURL}/api/auth/profile`, {
    method: "PUT",
    body: JSON.stringify(profile),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function changePassword(token: any, passwords: object) {
  const response = await fetch(`${baseURL}/api/auth/change-password`, {
    method: "PUT",
    body: JSON.stringify(passwords),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function uploadCanvasImage(token: any, body: any) {
  const response = await fetch(`${baseURL}/api/media/upload?path=canvas`, {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });

  const data = await response.json();
  return data;

};

export async function uploadProfileImage(token: any, body: any) {
  const response = await fetch(`${baseURL}/api/auth/upload-profile`, {
    method: "POST",
    body: body,
    headers: {
      Authorization: `Bearer ${token}`,
      // "Content-Type": "multipart/form-data",
    },
  });

  const data = await response.json();
  return data;

};

export async function inviteViaEmail(token: any, details: object) {
  const response = await fetch(`${baseURL}/api/canvas/invite-meeting`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}

export async function contactUs(details: object) {
  const response = await fetch(`${baseURL}/api/contact-us`, {
    method: "POST",
    body: JSON.stringify(details),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data;
}